var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"view-candidates"},[_c('section',{staticClass:"view-candidates-head"},[_c('v-form',{staticClass:"base-form search-form",on:{"submit":function($event){$event.preventDefault();return _vm.filter_form_submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AutoCompleteUserSearch',{ref:"autocomplete_user",attrs:{"placeholder":_vm.$t('recruiter.candidates.view.advance-search-placeholder')},on:{"searched_user":_vm.searched_user}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"1"}},[(
              _vm.advance_search_text ||
              _vm.search_job_id ||
              _vm.view_candidates_pagination.searched_skill_filter ||
              _vm.user_ids.length > 0
            )?_c('v-btn',{staticClass:"base-body-text mt-2 base-submit-button submit-btn",attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("candidate.jobs.search"))+" ")]):_c('v-btn',{staticClass:"base-body-text mt-2 base-disable-button submit-btn",attrs:{"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("candidate.jobs.search"))+" ")])],1)],1)],1)],1),_c('section',{staticClass:"view-candidates-body mt-5 mt-md-12"},[[_c('ViewCandidatesBody',{attrs:{"loading":_vm.loading,"matching_candidates":_vm.matching_candidates}}),(!_vm.job_id)?_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[(_vm.loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:(
            !_vm.loading &&
            _vm.get_all_candidates.results.length > 0 &&
            _vm.get_all_candidates.filtered_count >= 0
          )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.get_all_candidates.total / 12),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                  ? 'mdi-arrow-left'
                  : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                  ? 'mdi-arrow-right'
                  : 'mdi-arrow-left'},on:{"input":_vm.fetch_candidates_with_pagination},model:{value:(_vm.view_candidates_pagination.pagination),callback:function ($$v) {_vm.$set(_vm.view_candidates_pagination, "pagination", $$v)},expression:"view_candidates_pagination.pagination"}})],1)]:(
            !_vm.loading &&
            _vm.get_all_candidates.results.length > 0 &&
            _vm.get_all_candidates.total > 10
          )?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(_vm.get_all_candidates.total / 12),"total-visible":6,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                  ? 'mdi-arrow-left'
                  : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                  ? 'mdi-arrow-right'
                  : 'mdi-arrow-left'},on:{"input":_vm.fetch_candidates_with_pagination},model:{value:(_vm.view_candidates_pagination.pagination),callback:function ($$v) {_vm.$set(_vm.view_candidates_pagination, "pagination", $$v)},expression:"view_candidates_pagination.pagination"}})],1)]:_vm._e()],2):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }